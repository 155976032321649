import React from "react"

import "./HomeSection.css"

import Card from "./Card"
import CollectionCard from "./CollectionCard"

const HomeSection = ({
  name,
  cards,
  style,
  isCollection = false,
  customClass,
}) => {
  const renderCard = card => {
    if (isCollection) {
      return <CollectionCard card={card} />
    }
    return <Card card={card} />
  }

  return (
    <div
      className={`home-section ${customClass ? customClass : ""}`}
      style={style}
    >
      <div className="home-section-header">
        <span>{name}</span>
      </div>

      <div className="home-section-carousel">
        <div className="cards">{cards.map(card => renderCard(card))}</div>
      </div>
    </div>
  )
}

export default HomeSection
